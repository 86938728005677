<template>
  <div class="mb-8">
    <main class="grid grid-cols-10">
      <div
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark"
        :class="!isMobile && currentComponent == 'Professional' ? 'hidden' : ''"
      >
        <ProfileHeader :editMode="editProfileState">
          
          <ProfileNavbar
          :editMode="editProfileState"
            class="mt-8 lg:mt-0 dark:text-white col-start-0 relative col-span-10 md:col-start-2 md:col-span-7 lg:col-start-3 lg:col-span-7 items-center flex items-center lg:justify-between"
            @change="changeComponent"
            :navigation="navigation"
            :isIconVisible="isAuthorized"
            version="2"
          >
           
              <div
                v-if="currentComponent == 'Experience' && isAuthorized"
                :class="isMobile ? 'top-8 ' : 'top-0 right-0 absolute pr-4'"
                class="text-right lg:relative sm:inherit float-right md:pr-0"
              >
                <!-- class= mb-2" -->
                <button
                  @click="changeEditState"
                  class="cursor-pointer px-5 py-1 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark"
                >
                  + Add Production
                </button>
              </div>
              
              <EditProfileHeaderButton :currentComponent="currentComponent" v-if="currentComponent == 'Index'" />
              
              
              <div
                v-if="
                  currentComponent == 'Photos' && isAuthorized == true
                    ? setAddNewButtonForCreators(currentComponent)
                    : false
                "
                class="lg:text-right top-0 lg:relative sm:inherit float-right mt-1 lg:mt-0 lg:w-auto text-center"
                :class="isMobile ? 'top-0 ' : 'w-full absolute top-0 right-0'"
              >
                <!-- class= mb-2" -->
                <div class="tooltip relative inline-block">
                  <button
                    @click="toggle"
                    v-click-outside="hideToggle"
                    id="dropdownDividerButton"
                    data-dropdown-toggle="dropdownDivider"
                    class="cursor-pointer px-5 py-1 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark"
                    type="button"
                  >
                    Add
                    <svg
                      class="ml-2 w-4 h-4"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                  <!-- Dropdown menu -->
                  <div
                    id="dropdownDivider"
                    :class="showCreditCard"
                    class="z-10 w-32 shadow-basic-sm bg-white absolute text-left rounded divide-y divide-gray-100 dark:text-white dark:bg-primaryDark"
                  >
                    <ul
                      class="text-sm text-black dark:text-white py-3"
                      aria-labelledby="dropdownDividerButton"
                    >
                      <li
                        class="no-scroll-bar overflow-hidden overflow-y-scroll h-auto max-h-full
"
                      >
                        <a
                          @mouseup="this.addPhotoOn = true;this.photo_option=option"
                          class="block hover:bg-gray-100 p-2.5 dark-hover:bg-backgroundDark cursor-pointer"
                          v-for="(option, index) in addBtnOptions"
                          :key="index"
                          >{{ option }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                           
          </ProfileNavbar>
        </ProfileHeader>
      </div>
       <!--Mobile menu slider---->
      
    <ProfileSliderMenu
    :editMode="editProfileState"
            class="lg:mt-0 dark:text-white col-start-0 relative col-span-10 md:col-start-0 md:col-span-12 lg:col-start-3 lg:col-span-7 items-center flex items-center lg:justify-between"
            @change="changeComponent"
            :navigation="navigation"
             version="2"
      />
      <div
        :class="
          isAuthorized == true && currentComponent == 'Professional'
            ? 'col-span-10'
            : 'col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7'
        "
      >
        <transition name="fade">
          <component
            :is="currentComponent"
            :isAuthorized="isAuthorized"
            v-bind="dynamicProps"
            v-on="dynamicEvents"
          />
        </transition>
      </div>
    </main>
    <Modal :modalActive="this.addPhotoOn" @close="this.addPhotoOn = false" version='6'>
    <ProfilePhotoEdit @close="this.addPhotoOn = false"  :modalActive="this.addPhotoOn" :imagePoiSelectorOn="this.addPhotoOn" :albumType="this.photo_option"
    />
    </Modal>
    <ProfileHeaderDialog :open="editModeActive" @close="setIsHeaderDialogOpen(false)">
      <ProfileHeaderEdit @onClose="editModeActive = false" :album="this.photo_option"></ProfileHeaderEdit>
    </ProfileHeaderDialog>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, onBeforeMount, onUpdated } from "vue";
import { useRoute } from "vue-router";
import localStorage from "store2";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import Experience from "@/components/profile/view/pages/Experience";
import Index from "@/components/profile/view/pages/Index";
import { MenuIcon } from "@heroicons/vue/outline";
import Media from "@/components/profile/view/pages/Media";
import Contact from "../components/profile/view/pages/Contact";
import Professional from "@/components/profile/edit/pages/Professional";
import Photos from "@/components/profile/edit/pages/Photos";
import Videos from "@/components/profile/edit/pages/Videos";
import Audio from "@/components/profile/edit/pages/Audio";
import Documents from "@/components/profile/edit/pages/Documents";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
import registerEvents from "../utils/DisableSearchBoxInInputs";
import { PhotoAlbumNames } from "@/constants.js";
import ProfilePhotoEdit from "@/components/widgets/mediaImageWidget/MediaImageAddPhoto";
import Modal from "@/components/widgets/Modal";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeaderEdit from "@/components/profile/components/profileHeader/ProfileHeaderEdit";
import ProfileHeaderDialog from "@/components/profile/components/profileHeader/ProfileHeaderDialog";
import ProfileSliderMenu from "@/components/widgets/profileNavbar/ProfileSliderMenu";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";

const navigation = ref([
  {
    name: "Overview",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "Index",
    public: true,
    // icon: "EyeIcon",
    current: true,
  },
  {
    name: "Productions",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "Experience",
    public: true,
    // icon: "EyeIcon",
    current: false,
  },
  {
    name: "Photos",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "Photos",
    public: true,
    // icon: "EyeIcon",
    current: false,
  },
  {
    name: "Videos",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "Videos",
    public: true,
    // icon: "EyeIcon",
    current: false,
  },
  {
    name: "Audio",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "Audio",
    public: true,
    // icon: "EyeIcon",
    current: false,
  },
  {
    name: "Documents",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "Documents",
    public: true,
    // icon: "EyeIcon",
    current: false,
  },
  {
    name: "Professional",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "Professional",
    public: false,
    // icon: "EyeOffIcon",
    current: false,
  },
]);
function onNavClick(activeNode, navigation) {
  // console.log("click on navbar")
  for (let i = 0; i < navigation.length; i++) {
    navigation[i].current = false;
  }
  activeNode.current = true;
  setTimeout(registerEvents, 500);
}
function changeCurrentByName(name) {
  for (let i = 0; i < navigation.value.length; i++) {
    let cmp = navigation.value[i];
    cmp.current = cmp.component === name;
  }
}
export default {
  name: "Profile",
 components: {
    ProfileHeader,
    ProfileNavbar,
    Contact,
    Index,
    Media,
    Photos,
    Videos,
    Audio,
    Documents,
    Experience,
    Professional,
    EyeIcon,
    EyeOffIcon,
    ProfilePhotoEdit,
    Modal,
    MenuIcon,
    EditProfileHeaderButton,
    ProfileHeaderDialog,
    ProfileHeaderEdit,
    ProfileSliderMenu
  },
  computed: {
    dynamicProps() {
      if (this.currentComponent == "Experience") {
        return { isBtnClicked: this.isBtnClicked };
      }
      return {};
    },
    dynamicEvents() {
      if (this.currentComponent == "Experience") {
        return { onClose: this.closeEditState };
      }
      return {};
    },
  },
  
  data() {
    return {
      photo_option:'',
      newTabValue: "",
      showCreditCard: "hidden",
      addPhotoOn: false,
      curAlbumTitle: localStorage.get("curAlbumTitles"),
     
    };
  },
  methods: {
    changeEditState() {
      this.isBtnClicked = !this.isBtnClicked;
    },
    async getProfileLink() {
      this.currentComponent = "Professional";
      for (let i = 0; i < this.navigation.length; i++) {
        this.navigation[i].current = false;
        if (this.navigation[i].component == "Professional") {
          this.navigation[i].current = true;
        }
      }
    },
    getMobileMenu(currentComponent) {
      console.log(currentComponent);
      let newArr = [
        "General",
        "Skills",
        "Physical",
        "Diversity",
        "Medical",
        "Travel",
        "Programme",
        "Awards",
        "Professional",
      ];
      if (newArr.includes(currentComponent)) {
        return true;
      } else {
        return false;
      }
    },
    closeEditState() {
      this.isBtnClicked = false;
    },
    toggle() {
      if (this.showCreditCard === "hidden") {
        this.showCreditCard = "";
      } else {
        this.showCreditCard = "hidden";
      }
    },
    hideToggle() {
      setTimeout(() => {
        this.showCreditCard = "hidden"; // method to call when user is done typing
      }, 300);
    },
  },
  setup() {
    const editModeActive = ref(false);
    const { width } = useBreakpoints();
    let checkAlbum = localStorage.get("curAlbumTitles", PhotoAlbumNames.Photos);
    if (checkAlbum === null || checkAlbum === "") {
      localStorage.set("curAlbumTitles", PhotoAlbumNames.Photos);
    }
    const store = useStore();
    const route = useRoute();
    const addBtnOptions = ref([]);
    let productionMenu = ["Photos", "Profile Picture", "Headshots"];
    const isAuthorized = computed(() => store.getters.isUserAuthorizedToEdit);
    const isBtnClicked = ref(false);
    
    store.dispatch('fetchUserAlbum', PhotoAlbumNames.Photos);
    store.dispatch('fetchUserAlbum', PhotoAlbumNames.Headshots);

    onBeforeMount(async () => {
      await store.dispatch("fetchProfile", route.params.id);
      await store.dispatch("fetchProfileAlbum", {
        albumTitle: PhotoAlbumNames.ProfilePhotos,
        profileId: route.params.id,
      });
      if (isAuthorized.value) {
        store.dispatch("saveProfileEditState");
      }
    });
    onUpdated(async () => {
      if (isAuthorized.value) {
        store.dispatch("saveProfileEditState");
      }

      // this.curAlbumTitle = localStorage.get("curAlbumTitles");
    });
    const routeId = computed(() => route.params.id);
    let savedTab = ref("Index");
    let currentComponent = ref("Index");
    if (savedTab.value !== null) {
      currentComponent.value = savedTab.value;
      changeCurrentByName(savedTab.value);
    }
    const editProfileState = computed(() => store.getters.getProfileEditState);

    // function changeButtonState(target) {
    //   console.log("activate add new state", target, currentComponent.value);
    //   switch (currentComponent.value) {
    //     case "Photos":
    //       localStorage.remove("albumType");
    //       if (target == "Photos") {
    //         localStorage.set("albumType","Photos");
    //       } else if (target == "HeadShots") {
    //         localStorage.set("albumType","Headshots");
    //       } else {
    //         localStorage.set("albumType","ProfilePhotos");
    //       }

    //     default:
    //       return false;
    //   }
    // }
    function setAddNewButtonForCreators(comp) {
      if (comp == "Photos") {
        addBtnOptions.value = productionMenu;
      }
      return true;
    }
    function changeDropdown(event) {
      let button = event.target;
      button.classList.toggle("toggled");
    }

    return {
      editModeActive,
      routeId,
      store,
      editProfileState,
      currentComponent,
      isBtnClicked,
      changeDropdown,
      setAddNewButtonForCreators,
      // changeButtonState,
      addBtnOptions,
      isMobile: computed(() => width.value < 1024),
      navigation: computed(() =>
        navigation.value.filter((nav) => {
          if (nav.public || isAuthorized.value) {
            return nav;
          }
        })
      ),
      showOnboard: computed(() => store.getters.getUserOnboardState),
      isAuthorized,
      changeComponent(component) {
        currentComponent.value = component;
        localStorage.set("profileTab", component);
      },
      setIsHeaderDialogOpen(value) {
        editModeActive.value = value;
      },
    };
  },
  async beforeRouteUpdate(to, from, next) {
    await this.store.dispatch("fetchProfile", to.params.id);
    localStorage.set("profileTab", "Index");
    this.currentComponent = "Index";
    for (let i = 0; i < this.navigation.length; i++) {
      this.navigation[i].current = false;
    }
    this.navigation[0].current = true;
    next();
  },
  beforeUnmount() {
    // Reset navbar indicator
    for (let i = 0; i < this.navigation.length; i++) {
      this.navigation[i].current = false;
    }
    this.navigation[0].current = true;
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  created() {
    this.curAlbumTitle = localStorage.get("curAlbumTitles");

    this.$nextTick(() => {
      this.newTabValue = this.$route.query.name;
      let newVal = this.newTabValue ? this.newTabValue : "";
      console.log(this.newTabValue);
      if (newVal != "") {
        this.currentComponent = "Professional";
        for (let i = 0; i < this.navigation.length; i++) {
          this.navigation[i].current = false;
          if (this.navigation[i].component == "Professional") {
            this.navigation[i].current = true;
            this.navigation = navigation;
          }
        }
      }
    });
    console.log("queryAttributes");
  }
};
</script>
<style>
tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip:hover .innerBtn {
  width: 128px;
}
.shadow-basic {
  border: 1px solid #d1d5db;
}
.shadow-basic-sm {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
</style>
