<template>
  <div class="py-4 px-8 bg-white dark:bg-primaryDark rounded-lg">
    <div
      class="w-full border-b-2 mb-5 flex justify-between dark:border-gray-600"
    >
      <h1 class="font-medium text-2xl pr-4 dark:text-white">Videos</h1>
      <button
        v-if="editMode"
        @click="addVideoOn = true"
        :class="buttonClasses"
      >
        Add video
      </button>
    </div>
    <MediaVideoDisplayVideos :videos="videos" :editMode="editMode" />
    <Modal :modalActive="addVideoOn" @close="addVideoOn = false">
      <MediaVideoAddVideo @close="addVideoOn = false" />
    </Modal>
  </div>
</template>

<script>
import { ref } from "vue";
import Modal from "@/components/widgets/Modal";
import MediaVideoAddVideo from "@/components/widgets/mediaVideoWidget/MediaVideoAddVideo";
import MediaVideoDisplayVideos from "@/components/widgets/mediaVideoWidget/MediaVideoDisplayVideos";

export default {
  name: "MediaVideoWidget",
  components: { Modal, MediaVideoAddVideo, MediaVideoDisplayVideos },
  props: {
    editMode: Boolean,
    videos: Array,
  },
  computed: {
    buttonClasses() {
      return "ml-40 cursor-pointer m-2 px-4 py-2 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark";
    },
  },
  setup() {
    const addVideoOn = ref(false);

    return { addVideoOn };
  },
};
</script>
