<template>
  <div class="mb-8">
    <main>
      <div
        v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center"
      >
        <ProfileHeader class="mb-8" />
        <EditProfileHeaderButton />
      </div>

      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <ProfileSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9">
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="fa-fas fa-laptop-file"
                  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-5 md:h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out']"
                  aria-hidden="true"
                />
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Work & Education
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>
              <div v-if="!isMobile">
                <p
                  class="mb-3.5 mt-7 whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                >
                  Jobs / titles
                </p>
                <p>
                  Add job titles that are appropriate to you. These can appear in search
                  results by other users.
                </p>
                <div class="mt-2">
                  <MultiFieldInput
                    :suggestions="jobSuggestion"
                    :elements="secondaryJobs"
                    @onChange="searchJobs"
                    @addElement="addSecondaryJob"
                    @removeElement="deleteSecondaryJob"
                  />
                </div>
              </div>
              <div id="workExperienceList" class="w-full mt-10" v-if="!isMobile">
                <div class="justify-between col-span-full">
                  <div class="md:flex col-span-full itens-center mt-2 mb-2">
                    <p class="flex-1 flex col-span-full items-center text-lg font-medium">
                      Work experience
                    </p>
                    <button
                      type="button"
                      @click="editworkExperience(null)"
                      class="flex flex-row items-center mr-0 px-3 py-3 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer"
                    >
                      + Add Experience
                    </button>
                  </div>
                </div>
                <workExperienceTable
                  header="Emergency Contacts"
                  :values="experience"
                  @onDelete="deleteWorkExperience"
                  @onEdit="editworkExperience"
                  :fields="['role', 'department', 'company / venue', 'year']"
                />
              </div>
              <div id="educationList" class="w-full mt-10" v-if="!isMobile">
                <div class="justify-between col-span-full">
                  <div class="md:flex col-span-full itens-center mt-2 mb-2">
                    <p class="flex-1 flex col-span-full items-center text-lg font-medium">
                      Education
                    </p>
                    <button
                      type="button"
                      @click="editEducation(null)"
                      class="flex flex-row items-center mr-0 px-3 py-3 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer"
                    >
                      + Add Education
                    </button>
                  </div>
                </div>
                <educationTable
                  header="Emergency Contacts"
                  :values="educations"
                  @onDelete="deleteEducation"
                  @onEdit="editEducation"
                  :fields="['degree', 'institution', 'year']"
                />
              </div>
              <div id="affiliationsList" class="w-full mt-10 opacity-60 cursor-not-allowed pointer-events-none" v-if="!isMobile">
                <div class="justify-between col-span-full mt-10">
                  <h2
                    class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white"
                  >
                    Affiliations
                  </h2>
                  <div class="flex col-span-full itens-center mt-2 mb-2">
                    <p class="flex-1 flex col-span-full items-center text-sm">
                      Here you can add and edit your affiliations to societies, unions,
                      festivals etc.
                    </p>
                    <button
                      type="button"
                      @click="editMedicalCondition(null)"
                      class="flex flex-row items-center mr-0 px-3 py-3 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer"
                    >
                      + Add Affiliations
                    </button>
                  </div>
                </div>
                <AffiliationTable
                  :values="affiliationArr"
                  :fields="['association', 'member type', 'id number']"
                />
              </div>
              <app-accordion class="mt-0" v-if="isMobile">
                <template class="mt-0" v-slot:title>
                  <div class="flex items-center mt-0">
                    <p
                      class="mb-3.5 mt-7 whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                    >
                      Jobs / titles
                    </p>
                  </div>
                </template>
                <template v-slot:content>
                  <p>
                    Add job titles that are appropriate to you. These can appear in search
                    results by other users.
                  </p>
                  <div class="mt-2 mb-10">
                    <MultiFieldInput
                      :suggestions="jobSuggestion"
                      :elements="secondaryJobs"
                      @onChange="searchJobs"
                      @addElement="addSecondaryJob"
                      @removeElement="deleteSecondaryJob"
                    />
                  </div>
                </template>
              </app-accordion>
              <app-accordion class="mt-0" v-if="isMobile">
                <template class="mt-0" v-slot:title>
                  <div class="flex items-center mt-0">
                    <p
                      class="mb-3.5 whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                    >
                      Work experience
                    </p>
                  </div>
                </template>
                <template v-slot:content>
                  <div class="mb-10">
                    <div class="justify-between col-span-full mt-14">
                      <div class="md:flex col-span-full itens-center mt-2 mb-2">
                        <p
                          class="flex-1 flex col-span-full items-center text-lg font-medium"
                        ></p>
                        <button
                          type="button"
                          @click="editworkExperience(null)"
                          class="flex flex-row items-center mr-0 px-3 py-3 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer"
                        >
                          + Add Experience
                        </button>
                      </div>
                    </div>
                    <workExperienceTable
                      header="Emergency Contacts"
                      :values="experience"
                      @onDelete="deleteWorkExperience"
                      @onEdit="editworkExperience"
                      :fields="['role', 'department', 'company / venue', 'year']"
                />
                  </div>
                </template>
              </app-accordion>
              <app-accordion class="mt-0" v-if="isMobile">
                <template class="mt-0" v-slot:title>
                  <div class="flex items-center mt-0">
                    <p
                      class="mb-3.5 whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                    >
                      Education
                    </p>
                  </div>
                </template>
                <template v-slot:content>
                  <div class="mb-10">
                    <div class="justify-between col-span-full">
                      <div class="md:flex col-span-full itens-center mt-2 mb-2">
                        <p
                          class="flex-1 flex col-span-full items-center text-lg font-medium"
                        ></p>
                        <button
                          type="button"
                          @click="editEducation(null)"
                          class="flex flex-row items-center mr-0 px-3 py-3 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer"
                        >
                          + Add Education
                        </button>
                      </div>
                    </div>
                    <educationTable
                  header="Emergency Contacts"
                  :values="educations"
                  @onDelete="deleteEducation"
                  @onEdit="editEducation"
                  :fields="['degree', 'institution', 'year']"
                />
                  </div>
                </template>
              </app-accordion>
              <app-accordion class="mt-0" v-if="isMobile">
                <template class="mt-0" v-slot:title>
                  <div class="flex items-center mt-0">
                    <p
                      class="mb-3.5 whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                    >
                      Affiliations
                    </p>
                  </div>
                </template>
                <template v-slot:content>
                  <div class="mb-10 opacity-60 pointer-events-none">
                    <div class="justify-between col-span-full">
                      <div class="md:flex col-span-full itens-center mt-2 mb-2">
                        <p
                          class="flex-1 flex col-span-full items-center text-lg font-medium"
                        >
                          Here you can add and edit your affiliations to societies,
                          unions, festivals etc.
                        </p>
                        <button
                          type="button"
                          @click="editMedicalContact(null)"
                          class="flex flex-row items-center mr-0 px-3 py-3 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer"
                        >
                          + Add Affiliations
                        </button>
                      </div>
                    </div>
                    <AffiliationTable
                      :values="affiliationArr"
                      :fields="['association', 'member type', 'id number']"
                    />
                  </div>
                </template>
              </app-accordion>
              <div class="flex justify-end w-full px-4 mt-10">
                <SaveCancelForm
                  version="1"
                  :display="true"
                  justify-orientation="end"
                  @onCancel="onCancel"
                  @onSave="onSave"
                />
              </div>

              <workExperienceModal
                v-if="editworkExperienceOn"
                :workExperience="workExperience"
                @onSave="saveWorkExperience"
                @onCancel="cancelEditWorkExperience"
                @onDelete="deleteWorkExperience"
                @update:role="workExperience.role = $event"
                @update:department="workExperience.department = $event"
                @update:company="workExperience.company = $event"
                @update:venue="workExperience.venue = $event"
                @update:startDate="workExperience.startDate = $event"
                @update:endDate="workExperience.endDate = $event"
              />

              <educationTableModal
                v-if="editEducationOn"
                :education="education"
                @onSave="saveEducation"
                @onCancel="cancelEditEducation"
                @onDelete="deleteEducation"
                @update:institution="education.institution = $event"
                @update:yearStarted="education.yearStarted = $event"
                @update:yearFinished="education.yearFinished = $event"
                @update:degree="education.degree = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import { computed } from 'vue'
import { useStore } from "vuex";
import { computed, ref, reactive } from "vue";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import MultiFieldInput from "@/components/inputs/MultiFieldInput";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import workExperienceTable from "@/components/profile/components/workExperienceTable/workExperienceTable";
import educationTable from "@/components/profile/components/educationTable/educationTable";
import AffiliationTable from "@/components/profile/components/AffiliationTable/AffiliationTable";
import workExperienceModal from "@/components/profile/components/workExperienceTable/workExperienceModal";
import educationTableModal from "@/components/profile/components/educationTable/educationTableModal";
import { createToaster } from "@meforma/vue-toaster";
import { useRoute } from "vue-router";

import SaveCancelForm from "@/components/forms/SaveCancelForm";



export default {
  name: "Travel",
  components: {
    ExpandableBlockContent,
    ProfileSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
    SaveCancelForm,
    MultiFieldInput,
    AppAccordion,
    workExperienceTable,
    educationTable,
    AffiliationTable,
    workExperienceModal,
    educationTableModal,
  },
  data() {
    return {
      affiliationArr: [
        {
          association: "Félag íslenskra leikara (FÍL)",
          "member type": "Early career associate",
          "id number": "0123456789",
        },
        {
          association: "Association of British Theatre Technicians",
          "member type": "Associate member",
          "id number": "0123456789",
        },
        {
          association: "Screen Actors Guild (SAG-AFTRA)",
          "member type": "Individual",
          "id number": "05689",
        },
        {
          association: "Association of Lighting Production",
          "member type": "Honorary member",
          "id number": "01456789",
        },
      ],
    }
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    const editworkExperienceOn = ref(false);
    const editEducationOn = ref(false);
    let workExperience = reactive({});
    let education = reactive({});

    function cancelEditWorkExperience() {
      delete workExperience.role;
      delete workExperience.department;
      delete workExperience.company;
      delete workExperience.venue;
      delete workExperience.startDate;
      delete workExperience.endDate;
      delete workExperience.id;
      editworkExperienceOn.value = false;
      store.dispatch("fetchProfile", route.params.id);
    }

    function cancelEditEducation() {
      delete education.institution;
      delete education.yearStarted;
      delete education.yearFinished;
      delete education.degree;
      delete education.id;
      editEducationOn.value = false;
      store.dispatch("fetchProfile", route.params.id);
    }

    return {
      openMobile,
      store,
      route,
      workExperience,
      editworkExperienceOn,
      editEducationOn,
      education,
      cancelEditWorkExperience,
      cancelEditEducation,
      isMobile: computed(() => width.value < 1024),
      jobSuggestion: computed(() => store.getters.getProfileJobs.slice(0, 5)),
      secondaryJobs: computed(() =>
        store.getters.getUserSecondaryJobs.map((job) => job.title)
      ),
      searchJobs(val) {
        store.dispatch("fetchProfileJobs", val);
      },
      addSecondaryJob(title) {
        store.dispatch("createSecondaryJob", title);
      },
      deleteSecondaryJob(title) {
        store.dispatch("deleteSecondaryJob", title);
      },
      experience: computed(() => store.getters.getProfileWorkExperience),
      educations: computed(() => store.getters.getProfileEducation),
      editworkExperience(condition) {
        if (condition === null) {
          Object.assign(workExperience, {
            role: "",
            department: "",
            company: "",
            venue: "",
            startDate: null,
            endDate: null,
          });
        } else {
          Object.assign(workExperience, {
            role: condition.role,
            department: condition.department,
            company: condition.company,
            venue: condition.venue,
            startDate: condition.startDate,
            endDate: condition.endDate,
            id: condition.id,
          });
        }
        editworkExperienceOn.value = true;
      },
      async saveWorkExperience() {
        // If editing pre-existing medical contact
        const toast = createToaster({ position: "top-right" });
        if (Object.prototype.hasOwnProperty.call(workExperience, "id")) {
          await store.dispatch("editWorkExperience", {
            id: workExperience.id,
            role: workExperience.role,
            department: workExperience.department,
            company: workExperience.company,
            venue: workExperience.venue,
            startDate: workExperience.startDate,
            endDate: workExperience.endDate,
          });
          toast.success(`Work experience has been updated successfully`);
        } else {
          await store.dispatch("createWorkExperience", {
            role: workExperience.role,
            department: workExperience.department,
            company: workExperience.company,
            venue: workExperience.venue,
            startDate: workExperience.startDate,
            endDate: workExperience.endDate,
          });
          toast.success(`Work experience has been created successfully`);
        }

        cancelEditWorkExperience();
      },
      async deleteWorkExperience(ids) {
        let id = ids.id;
        const toast = createToaster({ position: "top-right" });
        await store.dispatch("editWorkExperience", { id, delete: true });
        cancelEditWorkExperience();
        toast.success(`Work experience has been deleted successfully`);
      },
      editEducation(condition) {
        if (condition === null) {
          Object.assign(education, {
            institution: "",
            yearStarted: null,
            yearFinished: null,
            degree: "",
          });
        } else {
          Object.assign(education, {
            institution: condition.institution,
            yearStarted: condition.yearStarted,
            yearFinished: condition.yearFinished,
            degree: condition.degree,
            id: condition.id,
          });
        }
        editEducationOn.value = true;
      },
      async saveEducation() {
        console.log(education);
        // If editing pre-existing medical contact
        const toast = createToaster({ position: "top-right" });
        if (Object.prototype.hasOwnProperty.call(education, "id")) {
          await store.dispatch("editEducation", {
            id: education.id,
            institution: education.institution,
            yearStarted: education.yearStarted,
            yearFinished: education.yearFinished,
            degree: education.degree,
          });
          toast.success(`Education has been updated successfully`);
        } else {
          await store.dispatch("createEducation", {
            institution: education.institution,
            yearStarted: education.yearStarted,
            yearFinished: education.yearFinished,
            degree: education.degree,
          });
          toast.success(`Education has been created successfully`);
        }

        cancelEditEducation();
      },
      async deleteEducation(ids) {
        let id = ids.id;
        const toast = createToaster({ position: "top-right" });
        await store.dispatch("editEducation", { id, delete: true });
        cancelEditEducation();
        toast.success(`Education has been deleted successfully`);
      },
      onSave() {},
      onCancel() {},
    };
  },
  computed: {
    pageContent: function () {
      return "The big cheese fromage cheese and wine. Swiss cottage cheese halloumi boursin roquefort cheese strings danish fontina blue castello. Stilton cheese strings red leicester everyone loves red leicester hard cheese cream cheese manchego. Manchego the big cheese feta airedale.";
    },
  },
};
</script>

<style scoped></style>
