<template>
  <div>
  <teleport to="#root">
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity" aria-hidden="true" @click="closeModal"></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
        overflow-hidden shadow-xl transform transition-all  sm:align-middle
       max-w-xl md:max-w-3xl lg:max-w-5xl sm:w-full sm:p-6 dark:bg-primaryDark dark:text-white  ">
          <div>
            <div class="mt-3 text-center sm:mt-5 " >
            <div class="flex mb-8 items-center">
              <UsersIcon class="w-7 mr-2" />
              <p class="text-2xl text-left ml-2 font-medium dark:text-gray-200 " id="modal-title">
                Edit Work Experience
              </p>
              </div>
               <div class="md:flex   gap-8">
                <SmallTextInput :value="workExperience.role" @update:value="$emit('update:role', $event)"  heading="Role" class="col-span-2 w-full"/>
                <SmallTextInput :value="workExperience.department" @update:value="$emit('update:department', $event)" heading="Department" class="mt-2 md:mt-0 col-span-2 w-full" />
                <SmallTextInput :value="workExperience.company" @update:value="$emit('update:company', $event)" heading="Company" class="mt-2 md:mt-0 col-span-2 w-full" />
                <SmallTextInput :value="workExperience.venue" @update:value="$emit('update:venue', $event)" heading="Venue" class="mt-2 md:mt-0 col-span-2 w-full" />
                <DateInput class="mt-2 md:mt-0 col-span-2 w-full" :value="workExperience.startDate"
                      @update:value="$emit('update:startDate', $event)" heading="Start Date" />
                <CurrentDateInput class="mt-2 md:mt-0 col-span-2 w-full" :value="workExperience.endDate"
                      @update:value="$emit('update:endDate', $event)" heading="End Date" />
               <div v-if="editMode"  class="w-5 md:w-32   cursor-pointer md:pt-5 md:pb-5 edit_button text-gray-600 dark:text-gray-200 mt-2"  @click="$emit('onDelete', workExperience)">
                  <TrashIcon class="w-5" />
                 </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 flex justify-between  flex-wrap gap-10">
           
            <SaveCancelForm :display="true" justifyOrientation='end' @onSave="$emit('onSave')" @onCancel="$emit('onCancel')" />
          </div>
        </div>
      </div>
    </div>
  </teleport>
  </div>
</template>
<script>
import {ref} from "vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import DateInput from "@/components/inputs/DateInput";
import CurrentDateInput from "@/components/inputs/CurrentDateInput";

import { TrashIcon ,UsersIcon} from '@heroicons/vue/outline';
export default {
  name: "workExperienceModal",
  components: { SmallTextInput, SaveCancelForm, TrashIcon,DateInput,CurrentDateInput,UsersIcon},
  props: {
    workExperience: { type: Object, required: true },
    backdrop: {
      required: false,
      default: true,
    },
   
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit("onCancel");
      }
    });
  },
  setup(props) {  
     const editMode = ref(props.workExperience.id !== undefined); // If contact has id prop then it can be deleted // const editMode = ref(props.contact.id != undefined); // If contact has id prop then it can be deleted
    return {
      editMode,
      severityTypes:["MILD","MODERATE","SEVERE"],
    }
  },
  methods: {
    closeModal() {
      if (this.backdrop) this.$emit("onCancel");
    },
  },
}
</script>