<template>
  <div class="w-full h-full mt-5">
    <div
      class="dark:bg-primaryDark rounded-lg"
    >

      <div class="flex flex-col-reverse md:flex-row dark:bg-primaryDark">
        <div class="w-full md:w-96 p-4 shadow-sm py-6 min-h-80  h-screen  bg-white dark:bg-primaryDark sticky top-0">
          <h2 class="m-2 text-xl">Edit Albums</h2>
          <section class="m-4 flex flex-col">
            <SmallTextInput class="w-full" heading="Album title" :value="albumTitle" />
            <input
              @change="uploadImage"
              name="file-input"
              ref="file"
              type="file"
              class="hidden"
              multiple
            />
            <button
              type="file"
              accept="image/jpeg"
              @click="$refs.file.click()"
              class="py-3 px-6 bg-gray-200 text-base w-full rounded-md font-semibold mt-4"
            >
              Upload photos
            </button>
            <SaveButton
              :title="title"
              :buttonClasses="transparentButtonClasses"
              @onSave="onSubmit"
            />
            
          </section>
        </div>
        <div
          class="w-full bg-gray-100 flex-1  min-h-80 p-8 flex justify-center md:justify-start dark:bg-primaryDark"
        >
          <ImageInput
            v-show="picturePreviews.length < 1"
            class="w-1/2 mx-auto m-6 dark:bg-primaryDark"
            :on-change="fileChange"
            :preview="false"
            :previews="picturePreviews"
          />
          <Lightgallery 
              :settings="{ speed: 500, plugins: plugins }"
              :onInit="onInit"
              :onBeforeSlide="onBeforeSlide"
              ref="gallery"
              id="lightGallery"
              class="object-cover grid gap-2 sm:gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 "
           >
              <a  v-for="(album, id) in picturePreviews"
                :key="id"
                data-lg-size="400-400"
                :data-src="album.image"
                :data-sub-html="getImageMeta(album)"
               
              >  
            <div
              class="rounded overflow-hidden shadow-sm h-auto bg-white"
            >
              <div class=" relative main_block flex  md:h-72 h-64 sm:h-auto">
                <img :src="album.image" class="w-full h-full object-cover rounded-t-lg" />
                <div
                  v-if="picturePreviews.length > 0"
                  class="hidden z-50  action_button absolute object-cover top-2 flex justify-end h-full w-full pr-2">
                  <div class="h-40 w-full flex justify-end pb-0.5">
                    <TrashIcon
                      @click="onDelete(id);onModalButton()"
                      class="h-6 cursor-pointer w-6 text-white bg-gray-500 p-1 ml-0.5 rounded"
                    />
                  </div>
                </div>
              </div>
              <div class="m-2">
                
                <LargeTextInput
                :value="album.caption"
                  @update:value="$emit('update', id, 'caption', $event)"
                  class="w-full resize-none"
                  placeholder="Caption Optional"
                  heading=""
                  @click="onModalButton()"
                />
              </div>
              </div>
              </a>
            </Lightgallery>
         
          </div>
        </div>
      </div>
    </div>
 
</template>

<script>
import { useStore } from "vuex";
import {  reactive,ref } from "vue";
import { TrashIcon } from "@heroicons/vue/outline/esm";
import SaveButton from "@/components/inputs/SaveButton";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import LargeTextInput from "@/components/inputs/LargeTextInput";
import {useRoute} from "vue-router";
import ImageInput from "@/components/inputs/ImageInput";
import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgtThumbnail from 'lightgallery/plugins/thumbnail';

let lightGallerys = null;

export default {
  name: "editAlbums",
  components: {  TrashIcon, SaveButton, SmallTextInput, LargeTextInput,ImageInput ,Lightgallery},
  data() {
    return {
      plugins: [lgZoom, lgVideo,lgFullscreen,lgAutoplay,lgtThumbnail], 
    }
  },
  props: {
    title: {
      required: false,
      default: "Save",
    },
  },
  computed: {
    transparentButtonClasses() {
      return "my-4 w-full justify-center inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
    },
  },
   methods: {
    onInit: (detail) => {
      console.log('lightGallery has been initialized', detail.instance);
     
      lightGallerys = detail.instance;
      lightGallerys.refresh();
    },
    onBeforeSlide: (detail) => {
        const { index, prevIndex } = detail;
            console.log(index, prevIndex);
    },
    onDelete(idx) {
      // this.picturePreviews.find()
      this.picturePreviews = [
        ...this.picturePreviews.slice(0, idx),
        ...this.picturePreviews.slice(idx + 1),
      ];
      this.form = [...this.form.slice(0, idx), ...this.form.slice(idx + 1)];

      this.$nextTick(() => {
        const gallery = this.$refs.gallery;
        console.log('AA',gallery.LG)
        if (gallery) {
            console.log('galleryItems++++++++++', gallery.LG.refresh());
            if (gallery.LG.galleryItems.length > 0) {
                gallery.LG.refresh();
            }
        }
        });

    },
    uploadImage(e) {
      const image = e.target.files;
      this.fileChange(image);
    },
    onSubmit() {
      console.log("submit", this.form);
    },
    onModalButton() {
      
        this.$nextTick(() => {
        const gallery = this.$refs.gallery;
        console.log('AA',gallery.LG)
        if (gallery) {
            console.log('galleryItems', gallery);
            if (gallery.LG.galleryItems.length > 0) {
                gallery.LG.destroy();
            }
        }
        });
     },
  },
  setup() {
    const store = useStore();
    const form = reactive([]);
    const route = useRoute();
    const picturePreviews = ref([]);
    const id = route.params.id;
   console.log('The id is: ' + id);
   const albumTitle = ref(null);
    if(id ==1){
      albumTitle.value='Forest';
      picturePreviews.value.push({
             caption: "Beach",
              image: "https://images.freeimages.com/images/large-previews/9c0/forest-1400475.jpg"
            },
            {
              caption: "Mountain",
              image: "https://images.freeimages.com/images/large-previews/8a2/white-trillium-on-forest-floor-1641920.jpg"
            },
            {
              caption:"Forest 123",
              image:"https://images.freeimages.com/images/large-previews/edb/forest-1258904.jpg"
            })
    }else{
      albumTitle.value='Animal';
      picturePreviews.value.push({
             
              caption: "Gathering",
              image: "https://images.freeimages.com/images/large-previews/6a6/deer-in-wild-1641752.jpg"
            },
            {
            
              caption: "Celebration",
              image: "https://images.freeimages.com/images/large-previews/83d/two-young-doe-in-a-field-affectionately-interacting-1640197.jpg"
            })
    }
    return {
      store,
      form,
      albumTitle,
      picturePreviews,
      fileChange: async (files) => {
        console.log(files);
        for (let i = 0; i < files.length; i++) {
        
          picturePreviews.value.push({
            image:URL.createObjectURL(files[i]),
            caption: "",
          });
        }
 
  
      },
      getImageMeta(img){
          return "<h4>Title "+albumTitle.value+"</h4><p> Caption "+img.caption+"</p>";
            },
    };
  },
};
</script>
<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-thumbnail.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-autoplay.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-rotate.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-fullscreen.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-pager.css');
body {
  margin: 0;
}
.gallery-item {
  margin: 5px;
}
</style>