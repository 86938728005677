<template>
  <div>
    <experience version="2" :editState="isBtnClicked" @onClose="$emit('onClose')"> </experience>
  </div>
</template>

<script>
import Experience from "@/components/profile/edit/pages/Experience";
export default {
  name: "Experience1",
  components: {
    Experience,
  },
  props: {
    isBtnClicked: {
      required: true,
    },
  }
};
</script>
