<template>
    <div class="bg-white dark:bg-primaryDark rounded-md">
        <LoadingSpinner class="w-full h-96 " v-if="store.getters.isLoading"/>
        <div v-else>
            <ProfileNavbar
                class="col-start-1 col-span-10 md:col-start-2 md:col-span-8
                       lg:col-start-3 lg:col-span-6"
                @change="changeComponent"
                :noHide="true"
                :navigation="navigation"
                :subNav="true"
            />
             <transition name="fade">
               <component
                    :editMode="isUserAuthorizedToEdit"
                    :videos="videos"
                    :images="images"
                    :is="currentComponent"
                    album="photos"
                />
             </transition>

        </div>
    </div>
</template>

<script>

import { computed, ref } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';
import localStorage from 'store2'
import MediaImageWidget from '@/components/widgets/mediaImageWidget/MediaImageWidget'
import MediaVideoWidget from '@/components/widgets/mediaVideoWidget/MediaVideoWidget'
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar.vue";
import { mapGetters } from "vuex";

const navigation = ref([
  { name: 'Photos', onClick:function(){onNavClick(this, navigation.value)}, component: 'MediaImageWidget', current: true },
  { name: 'Videos', onClick:function(){onNavClick(this, navigation.value)}, component: 'MediaVideoWidget', current: false },
])

function onNavClick(activeNode, navigation) {
  for(let i=0; i < navigation.length; i++) { navigation[i].current = false }
  activeNode.current = true
}

function changeCurrentByName(name) {
  for (let i = 0; i < navigation.value.length; i++) {
    let cmp = navigation.value[i];
    cmp.current = (cmp.component === name);
  }
}

export default {
    name: "Media",
    props: {},
    computed: {
    ...mapGetters(['isUserAuthorizedToEdit'])
    },
    components: { ProfileNavbar, MediaVideoWidget, MediaImageWidget, LoadingSpinner },
    setup() {
        const store = useStore()
        const route = useRoute()

        let savedTab = localStorage.get('profileMediaTab')
        let currentComponent = ref('MediaImageWidget')
        if(savedTab !== null) {
          currentComponent.value = savedTab
          changeCurrentByName(savedTab)
        }

        store.dispatch('fetchProfileMediaImages', route.params.id)
        store.dispatch('fetchProfileMediaVideos', route.params.id)
        return {
            changeComponent(component){
              localStorage.set("profileMediaTab", component)
              currentComponent.value = component
            },
            navigation,
            currentComponent,
            store,
            images: computed(() => store.getters.getProfileImages),
            videos: computed(() => store.getters.getProfileVideos)
        }
    }
}
</script>
