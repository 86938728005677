<template>
    <div class="relative min-h-40 w-full pt-9 grid grid-cols-4 bg-white rounded flex gap-4 px-7
                py-2 mb-4 shadow-md justify-center items-center dark:bg-gray-800 overflow-hidden"
                >
        
        <!-- Thumbnail -->
        <div class="col-span-1 w-full h-full" @click="$refs.file.click()">
            <div v-if="form.thumbnail == ''"
                 class="bg-gray-400 w-full h-full flex cursor-pointer justify-center items-center">
                <p class="text-white">Add thumbnail</p>
            </div>
            <img :src="form.thumbnail" class="object-fit w-full col-span-1 rounded max-h-40"/>
        </div>
        <input @change="fileChange($event.target.name, $event.target.files)" 
                name="file-input" ref="file" type="file" class="hidden" multiple/>
        <!-------->

        <div class="col-span-3 grid grid-cols-2 gap-3">
            <SmallTextInput @update:value="form.title=$event"
                            class="col-span-2" heading="Title" />

            <SmallTextInput @update:value="form.url=$event; fetchThumbnail()"
                            class="col-span-2" heading="URL" />
        </div>

        <button class="col-span-4 border-2 flex items-center justify-center rounded-lg py-2"
                @click="captionOn=!captionOn">
            Caption 
            <ChevronDownIcon class="h-4 ml-1" :class="[ captionOn ? 'transform transition-all rotate-180' : '']" />
        </button>
        <slide-up-down v-model="captionOn" class="col-span-4" :duration="400">
            <LargeTextInput @update:value="form.caption=$event" class="col-span-4 " heading="" />
        </slide-up-down>
        <div class="col-span-4 ">
         <SaveCancelForm @onSave="submit" @onCancel="$emit('close')"
            :display="!isError" justifyOrientation="end" />
        </div>
    </div> 
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { ChevronDownIcon } from '@heroicons/vue/outline/esm'
import SlideUpDown from 'vue3-slide-up-down'
import SmallTextInput from '@/components/inputs/SmallTextInput'
import LargeTextInput from '@/components/inputs/LargeTextInput'
import SaveCancelForm from '@/components/forms/SaveCancelForm'
export default {
    name: "MediaVideoAddVideo",
    components: { SmallTextInput, LargeTextInput, SlideUpDown, ChevronDownIcon, SaveCancelForm },
    setup( props, { emit }) {
        const store = useStore();
        const captionOn = ref(false)
        const customThumbnail = ref(false)
        const isError = ref(true)
        const form = reactive({
            file: "",
            thumbnail: "",
            title: "",
            url: "",
            caption: ""
        }) 
        return { 
            form,
            captionOn,
            customThumbnail,
            isError,
            async fetchThumbnail() {
                console.log('fetchThumbnail');
                if (customThumbnail.value) return;

                const res = await store.dispatch("fetchThumbnail", form.url) 
                
                if ( res.error == null ) {
                    isError.value = false;
                    form.thumbnail = res.thumbnail;
                } else {
                    isError.value = true;
                }
            },
            fileChange(_, files) {
                form.thumbnail = URL.createObjectURL(files[0])            
                form.file = files[0]
                customThumbnail.value = true
            },
            async submit() {
                await store.dispatch("createMediaVideo", {
                    file: form.file,
                    title: form.title,
                    url: form.url,
                    caption: form.caption,
                    profileId: store.getters.getId
                })
                emit('close');
            }
        }
    }
}
</script>

