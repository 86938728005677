<template>
  <div>
  
    <div v-if="!isMobile">
      <p class="whitespace-nowrap text-lg pr-2 font-semibold"  >Measurements</p>
        <div class="w-full grid items-start grid-cols-2 xl:grid-cols-2 md:gap-x-10 gap-1.5 mt-3" >
          <SmallTextInput heading="Height"  :tooltip="true"  textFormate="capitalize"
                    toolText="Height" class="col-span-2 md:col-span-1 my-1"
                    
                     />
          <SmallTextInput heading="Chest/bust"  :tooltip="true"  textFormate="capitalize"
                    toolText="Chest/bust" class="col-span-2 md:col-span-1 my-1"
                    
                     />
                     <SmallTextInput heading="Waist"  :tooltip="true"  textFormate="capitalize"
                    toolText="Waist" class="col-span-2 md:col-span-1 my-1"
                  
                     />
          <SmallTextInput heading="Hip"  :tooltip="true"  textFormate="capitalize"
                    toolText="Hip" class="col-span-2 md:col-span-1 my-1"
                    
                     />
          <SmallTextInput heading="Inseam"  :tooltip="true"  textFormate="capitalize"
                    toolText="Inseam" class="col-span-2 md:col-span-1 my-1"
                    
                     />
            <SmallTextInput heading="Head"  :tooltip="true"  textFormate="capitalize"
          toolText="Head" class="col-span-2 md:col-span-1 my-1"
            
            />
            <SmallTextInput heading="Collar"  :tooltip="true"  textFormate="capitalize"
                    toolText="Preferred train carriage" class="col-span-2 md:col-span-1 my-1"
                  
                     />
            <SmallTextInput heading="Shoe size"  :tooltip="true"  textFormate="capitalize"
          toolText="Shoe size" class="col-span-2 md:col-span-1 my-1"  />
        
        </div>
    </div>
    <app-accordion class=""  v-if="isMobile">
      <template v-slot:title>
        <div class="flex items-center">
          <p class="whitespace-nowrap text-lg pr-2 font-semibold" >Measurements</p>
          <div class="h-px w-full bg-gray-400"></div>
        </div>
      </template>
      <template v-slot:content>
        <!-- <UserIcon v-if="!custom" class="w-20 pr-4 pl-10 my-1 text-gray-500 dark:text-white"/> -->
    
      <div class="w-full grid items-start grid-cols-2 xl:grid-cols-2 md:gap-x-10 gap-1.5 mt-3" >
        <SmallTextInput heading="Height"  :tooltip="true"  textFormate="capitalize"
                    toolText="Height" class="col-span-2 md:col-span-1 my-1"
                    
                     />
          <SmallTextInput heading="Chest/bust"  :tooltip="true"  textFormate="capitalize"
                    toolText="Chest/bust" class="col-span-2 md:col-span-1 my-1"
                    
                     />
                     <SmallTextInput heading="Waist"  :tooltip="true"  textFormate="capitalize"
                    toolText="Waist" class="col-span-2 md:col-span-1 my-1"
                  
                     />
          <SmallTextInput heading="Hip"  :tooltip="true"  textFormate="capitalize"
                    toolText="Hip" class="col-span-2 md:col-span-1 my-1"
                    
                     />
          <SmallTextInput heading="Inseam"  :tooltip="true"  textFormate="capitalize"
                    toolText="Inseam" class="col-span-2 md:col-span-1 my-1"
                    
                     />
            <SmallTextInput heading="Head"  :tooltip="true"  textFormate="capitalize"
          toolText="Head" class="col-span-2 md:col-span-1 my-1"
            
            />
            <SmallTextInput heading="Collar"  :tooltip="true"  textFormate="capitalize"
                    toolText="Preferred train carriage" class="col-span-2 md:col-span-1 my-1"
                  
                     />
            <SmallTextInput heading="Shoe size"  :tooltip="true"  textFormate="capitalize"
          toolText="Shoe size" class="col-span-2 md:col-span-1 my-1"  />
        
        </div>
      </template>
    </app-accordion>
  </div>
</template>

<script>
import SmallTextInput from "@/components/inputs/SmallTextInput";
// import { QuestionMarkCircleIcon } from '@heroicons/vue/solid/esm'

import { useStore } from "vuex";
import { computed } from "vue";
import useBreakpoints from "@/utils/reactive/useBreakpoints"
import AppAccordion from "@/components/widgets/accordion/AppAccordion";

// import { UserIcon } from "@heroicons/vue/outline/esm";
export default {
  name: "TravelComfortInfoForm",
  components: {  AppAccordion, SmallTextInput  },
  props: {
    custom: {
      required: false,
      default: false,
    },
  },
  computed: {
    // paraClasses(){
    //   if(this.custom) return ""
    //   return "pl-10"
    // },
    iconClasses() {
      if (this.custom) return "w-8 pr-1";
      return "w-20 pl-10 pr-4";
    },
    outerClasses() {
      if (this.custom) return "grid-cols-2 xl:grid-cols-2 gap-x-2";
      return "grid-cols-2 xl:grid-cols-3 md:gap-x-10 gap-1.5";
    },
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints()
    return {
      store,
      isMobile: computed(() => width.value < 1024),
    
    };
  },
};
</script>
