<template>
<div>
    <OverviewPage :editMode="false" />
</div>
</template>

<script>
import OverviewPage from '@/components/profile/components/overviewPage/OverviewPage'
export default {
    name: "Index",
    components: { OverviewPage }
}
</script>

