<template>
  <tr class="dark:text-white text-gray-900 bg-white border-t dark:bg-gray-900 dark:border-gray-700">
    <td class="py-4 px-6" v-for="(field, idx) in fields" :key="idx">
    
     <p v-show="(field != 'year' )">{{ item[field] }} </p>
      <p v-show="( field == 'year')"> {{ getYearFromString(item['yearStarted']) }} - {{ getYearFromString(item['yearFinished']) }}</p>
      
    </td>
    <td class="py-4 px-6 flex">
      <button @click="$emit('onEdit', item)"><PencilIcon class="h-5 pt-1 w-5 dark:text-white"/></button>
      <button @click="$emit('onDelete', item)"><TrashIcon class="h-5 pt-1 w-5 dark:text-white"/></button>
    </td>
  </tr>
</template>

<script>
import {PencilIcon, TrashIcon} from '@heroicons/vue/outline';
export default {
  name: "MedicalTableUnit",
  components: {PencilIcon,TrashIcon},
  props: {
    fields: Array,
    item: Object
  },
  setup() {
    return{
      getYearFromString(s) {
        if (s === null) return " - ";
        return new Date(s).getFullYear();
      },
    }
  }
}
</script>
