<template>
  <div class="w-full h-full mt-5">
    <div
      class="dark:bg-primaryDark rounded-lg"
    >

      <div class="flex flex-col-reverse md:flex-row dark:bg-primaryDark">
        <div class="w-full md:w-96 p-4 shadow-sm py-6 min-h-80  h-screen  bg-white dark:bg-primaryDark sticky top-0">
          <h2 class="m-2 text-xl">Create Albums</h2>
          <section class="m-4 flex flex-col">
            <SmallTextInput class="w-full" heading="Album title" />
            <input
              @change="uploadImage"
              name="file-input"
              ref="file"
              type="file"
              class="hidden"
              multiple
            />
            <button
              type="file"
              accept="image/jpeg"
              @click="$refs.file.click()"
              class="py-3 px-6 bg-gray-200 text-base w-full rounded-md font-semibold mt-4"
            >
              Upload photos
            </button>
           <SaveButton
              :title="title"
              :buttonClasses="transparentButtonClasses"
              @onSave="onSubmit"
            />
          </section>
        </div>
        <div
          class="w-full bg-gray-100 flex-1  min-h-80 p-8 flex justify-center md:justify-start dark:bg-primaryDark"
        >
          <ImageInput
            v-show="picturePreviews.length < 1"
            class="w-1/2 mx-auto m-6 dark:bg-primaryDark"
            :on-change="fileChange"
            :preview="false"
            :previews="picturePreviews"
          />
          <div
            v-show="picturePreviews.length > 0"
            class="content-start object-cover grid gap-2 sm:gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 w-full"
          >
            <div
              v-for="(album, id) in picturePreviews"
              :key="id"
              class="rounded overflow-hidden shadow-sm h-full bg-white"
            >
              <div class=" relative main_block flex  md:h-72 h-64 sm:h-auto">
                <img :src="album" class="w-full h-full object-cover rounded-t-lg" />
                <div
                  v-if="picturePreviews.length > 0"
                  class="hidden action_button absolute object-cover top-2 flex justify-end h-full w-full pr-2">
                  <div class="h-40 w-full flex justify-end pb-0.5">
                    <TrashIcon
                      @click="onDelete(id)"
                      class="h-6 cursor-pointer w-6 text-white bg-gray-500 p-1 ml-0.5 rounded"
                    />
                  </div>
                </div>
              </div>
              <div class="m-2">
                <LargeTextInput
                  @update:value="$emit('update', id, 'caption', $event)"
                  class="w-full resize-none"
                  placeholder="Caption Optional"
                  heading=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, reactive } from "vue";
import ImageInput from "@/components/inputs/ImageInput";
import { TrashIcon } from "@heroicons/vue/outline/esm";
import SaveButton from "@/components/inputs/SaveButton";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import LargeTextInput from "@/components/inputs/LargeTextInput";

export default {
  name: "Diversity",
  components: { ImageInput, TrashIcon, SaveButton, SmallTextInput, LargeTextInput },
  props: {
    title: {
      required: false,
      default: "Save",
    },
  },
  computed: {
    transparentButtonClasses() {
      return "my-4 w-full justify-center inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
    },
  },
  methods: {
    onDelete(idx) {
      // this.picturePreviews.find()
      this.picturePreviews = [
        ...this.picturePreviews.slice(0, idx),
        ...this.picturePreviews.slice(idx + 1),
      ];
      this.form = [...this.form.slice(0, idx), ...this.form.slice(idx + 1)];
    },
    uploadImage(e) {
      const image = e.target.files;
      this.fileChange(image);
    },
    onSubmit() {
      console.log("submit", this.form);
    },
  },
  setup() {
    const store = useStore();
    const picturePreviews = ref([]);
    const form = reactive([]);
    return {
      store,
      form,
      picturePreviews,
      fileChange: async (files) => {
        console.log(files);
        for (let i = 0; i < files.length; i++) {
          picturePreviews.value.push(URL.createObjectURL(files[i]));
          form.push({
            image: files[i],
            preview: URL.createObjectURL(files[i]),
            caption: "",
          });
        }
      },
    };
  },
};
</script>
