<template>
    <div class="relative" @click="$emit('openPlayer')">
        <div  class="absolute object-cover top-0 flex justify-center items-end h-full w-full ">
            <div class="bg-gray-400 md:p-0 md:h-6 md:mb-6 lg:mb-12 lg:h-10 lg:p-2 w-full 
            flex justify-center gap-2 ">
                <FilmIcon class="w-5" />
                <p v-if="video.title">{{video.title}}</p>

            </div> 
        </div>
        <img :src="video.thumbnail" class="w-full rounded-md" />
        <div v-if="editMode"  class="absolute object-cover top-0 flex justify-end h-full w-full ">
            <div class="bg-white h-5 w-10 flex justify-end pb-0.5 dark:bg-primaryDark">
                <TrashIcon @click.stop="deleteVideo" class="h-full cursor-pointer w-auto bg-gray-400 text-white p-0.5 ml-0.5 dark:bg-gray-700 text-white"/>
            </div>
        </div>
    </div> 
</template>

<script>
import { FilmIcon, TrashIcon } from '@heroicons/vue/outline/esm'
import { useStore } from 'vuex'

export default {
    name: "MediaVideoDisplayUnit",
    components: { FilmIcon, TrashIcon },
    props: {
        video: Object,
        editMode: Boolean
    },
    setup(props) {
        const store = useStore()

        return {
            deleteVideo() {
                store.dispatch("deleteUserMediaVideo", props.video.uuid)
            } 
        }
    }
}
</script>

