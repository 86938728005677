<template>
  <tr class="dark:text-white text-gray-900 bg-white border-t dark:bg-gray-900 dark:border-gray-700">
    <td class="py-4 px-6" v-for="(field, idx) in fields" :key="idx">
    
      <p v-show="(field =='name / agency' )">{{ item['title'] }} / {{ item['subtittle'] }}</p>
      <p v-show="(field == 'role')">{{ item[field] }} </p>
      <p v-show="( field == 'contact' || field == 'photos' || field == 'programme' || field == 'travel')"><input type="checkbox" :checked="item[field] " > </p>
      
    </td>
    <td class="py-4 px-6 flex">
      <button @click="$emit('onSelect', item)"><PencilIcon class="h-5 pt-1 w-5 dark:text-white"/></button>
      <button @click="$emit('onSelect', item)"><TrashIcon class="h-5 pt-1 w-5 dark:text-white"/></button>
    </td>
  </tr>
</template>

<script>
import {PencilIcon, TrashIcon} from '@heroicons/vue/outline';
export default {
  name: "MedicalTableUnit",
  components: {PencilIcon,TrashIcon},
  props: {
    fields: Array,
    item: Object
  },
  setup() {

  }
}
</script>
