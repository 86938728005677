<template>
  <div class="mb-8">
    <main>
      <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton  />
      </div> 
      
      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
      <ProfileSidebar
        class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
        :subNav="true"
      />
    </div>
    <div
      class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
    >
  <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9  opacity-60 cursor-not-allowed pointer-events-none" >
    <div class="flex items-center gap-2">
      <font-awesome-icon :icon="['fas', 'weight-scale']"  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0  h-5 md:h-6  overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
      <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
        Measurements
      </h1>
    </div>
    <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6 ">
      <!-- <ExclamationCircleIcon class="w-4" /> -->
     
      <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
    </div>
    <MeasurementsForm />

    <p class="whitespace-nowrap text-lg pr-2 font-semibold mt-10"  >Current look photos</p>
    <p class="mb-5 text-sm">These are important for casting directors and costume designers.</p>
    <div class="grid  grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 xl:gap-x-5">
                   <div class="relative border-2 main_block flex h-40 2xl:h-96 lg:h-80 md:h-full p-2 md:h-64 sm:h-72" v-for="img in images" :key="img.id">
                    <div class="bg-white w-full h-full overflow-hidden object-center max-w-fit">
                    <!-- <label for="">{{img.title}}</label> -->
                    <p class="pt-0 pb-2 text-xl sm:text-sm">{{img.title}}</p>
                      <div class="relative w-full 2xl:h-72 lg:h-80  md:h-28 sm:h-64">
                        <img :src="img.image" class="w-full h-full object-cover" />
                        <div class="action_button absolute object-cover top-0 flex justify-end h-full w-full sm:top-0  md:top-0 ">
                            <div class="h-5 w-full flex justify-end pb-0.5 ">
                                <PencilIcon  class="h-8 cursor-pointer w-8 text-white opacity-60 bg-gray-500 p-2"/>
                                <TrashIcon   class="h-8 cursor-pointer w-8 text-white opacity-60 bg-gray-500 p-2"/>
                            </div>
                        </div>
                      </div>
                      <p class=" pt-4 pb-2  flex items-center justify-center left-0 right-0 text-sm"><font-awesome-icon icon="fa-solid fa-arrow-down" class="mr-1" />  Date taken: 14/02/2020</p>
                      </div>  
                    </div>    
    </div>
    <div class="flex justify-end w-full px-4 mt-10">
        <SaveCancelForm
               version="1"
               :display="true"
               justify-orientation="end"
                @onCancel="onCancel"
                @onSave="onSave"
            />
      </div>
  </div>
  </div>
  </div>
  </div></main></div>
</template>

<script>
// import { computed } from 'vue'
import {useStore} from "vuex";
import {computed,ref} from "vue";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import MeasurementsForm from "@/components/forms/MeasurementsForm";
import { TrashIcon, PencilIcon } from '@heroicons/vue/outline/esm'

import SaveCancelForm from "@/components/forms/SaveCancelForm";
export default {
  name: "Travel",
  components: {ExpandableBlockContent,
    ProfileSidebar,ProfileHeader ,  
    EditProfileHeaderButton,
    SaveCancelForm,
    MeasurementsForm,
    TrashIcon, PencilIcon
     },
  setup(){
    const store = useStore()
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
      images:[{'image':'https://cdn.pixabay.com/photo/2023/05/23/15/13/new-8012937_1280.jpg','title':'Full body front'},{'image':'https://cdn.pixabay.com/photo/2023/05/30/15/12/blue-butterfuly-8028888_640.jpg','title':'Full body back'},{'image':'https://cdn.pixabay.com/photo/2023/06/29/10/33/lion-8096155_640.png','title':'Face front'},{'image':'https://cdn.pixabay.com/photo/2023/07/13/17/32/fly-8125379_640.jpg','title':'Back of head'},{'image':'https://cdn.pixabay.com/photo/2023/07/05/04/45/european-shorthair-8107433_640.jpg','title':'Face left side'},{'image':'https://cdn.pixabay.com/photo/2022/08/17/15/46/labrador-7392840_640.jpg','title':'Face right side'}],
      onSave(){

      },
      onCancel(){
        
      }
    }
  },
  computed: {
      pageContent: function () {
        return 'Registering your travel information allows for travel arrangements to be made with ease. It will only be made available to your supervisors or managers, e.g. tour manager, company manager, stage manager etc. Future functionality will allow you to see how your information is accessed and control it more thoroughly.'
      },
    },
}
</script>

<style scoped>

</style>
