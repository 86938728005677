<template>
  <div class="overflow-x-scroll shadow ring-1 ring-black border ring-opacity-5 md:rounded-lg lg:over-x-hidden  whitespace-nowrap">
    <table class="w-full text-gray-900 text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="bg-gray-50 dark:bg-primaryDark">
      <tr class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-white">
        <th v-for="(field, idx) in fields" :key="idx" scope="col" class="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white">
          <p class="inline-block"> {{ field }} <svg width="8" 
            class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg> </p>
        </th>
        <th scope="col" class="py-4 px-6"></th>
      </tr>
      </thead>
      <tbody>
        <educationTableUnit  @onDelete="$emit('onDelete', $event)" @onEdit="$emit('onEdit', $event)"  v-for="(item, idx) in values" :item="item" :fields="fields" :key="idx" :class="idx % 2 === 0 ? 'bg-white dark:bg-primaryDark' : 'bg-gray-50 dark:bg-backgroundDark'" />
      </tbody>
    </table>
  </div>
</template>
<script>
import educationTableUnit from "@/components/profile/components/educationTable/educationTableUnit.vue";
export default {
  name: "educationTable",
  components: { educationTableUnit},
  props: {
    header: String,
    values: Array,
    fields: Array
  },
}
</script>