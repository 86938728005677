<template>
    <div>
        <div class="grid grid-cols-3 gap-8">
            <MediaVideoDisplayUnit v-for="vid in videos" 
                @openPlayer="onOpenPlayer(vid.player)"
                :key="vid.id" :video="vid" :editMode="editMode" />
        </div> 
        <Modal :modalActive="playerOn" @close="playerOn=false">
            <div ref="videoPlayer" id="video-player"
                class="h-screen w-full flex justify-center">
            </div>
        </Modal>
    </div> 
</template>

<script>
import { ref } from 'vue'
import MediaVideoDisplayUnit from './MediaVideoDisplayUnit.vue'
import Modal from '@/components/widgets/Modal'

export default {
    name: "MediaVideoDisplayVideos",
    props: {
        videos: Array,
        editMode: Boolean
    },
    components: { MediaVideoDisplayUnit, Modal },
    setup() {
        const playerOn = ref(false)
        const videoPlayer = ref(null)
        

        return {
            playerOn,
            videoPlayer,
            onOpenPlayer(player) {
               playerOn.value = true;
                setTimeout(() => {
                   videoPlayer.value.innerHTML = player
                   let playerElement = videoPlayer.value.firstChild;
                   playerElement.classList.add("w-full")
                   playerElement.classList.add("h-auto")
                   //playerElement.webkitRequestFullscreen()
                }, 50) 
               //const div = document.querySelector("#video-player")
               //div.innerHTML = player;
            }
        }
    }

}
</script>
<style scoped>
</style>>
